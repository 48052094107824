<template>
  <DisplayContainer>
    <div class="blog-head">
      <div
        class="picture-wrapper"
        v-if="blogImage && blogImage.filename && blogImage.filename.length > 0"
      >
        <img
          :src="blogImage.filename"
          :alt="blogImage.alt"
          class="blog-picture"
        />
      </div>
      <div v-else style="color: red; font-size: xx-large; font-weight: 900">
        Please select a blog picture
      </div>

      <div class="blog-container">
        <div class="blog-head">
          <h1>{{ title }}</h1>
        </div>

        <div class="blog-meta">
          <div class="left" v-if="author.name">
            <div class="author-img-border">
              <img
                :src="author.picture.filename"
                :alt="author.picture.alt"
                class="author-img"
              />
            </div>
            <div class="author-info">
              <p class="author-name">{{ author.name }}</p>
              <p class="author-position">{{ author.role }}</p>
            </div>
          </div>
          <div v-else style="color: red; font-size: xx-large; font-weight: 900">
            Please select an author
          </div>

          <p class="date" v-if="pub_date">
            <Icon name="fa-regular:calendar-alt" />{{ pub_date }}
          </p>
          <div v-else style="color: red; font-size: xx-large; font-weight: 900">
            This story is not yet published / Select a Sort by date
          </div>
        </div>
      </div>
    </div>
  </DisplayContainer>
</template>

<script setup lang="ts">
const props = defineProps<{
  blogImage?: {
    filename: string;
    alt: string;
  };
  blogAuthor?: string;
  title: string;
  publishedDate?: string;
}>();

// Transfor the ISO date into a readable string
const pub_date = computed(() => {
  if (props.publishedDate) {
    const JSDate = new Date(props.publishedDate.substring(0, 10));
    const day = JSDate.getUTCDate();
    const month = JSDate.toLocaleDateString("default", { month: "long" });
    const year = JSDate.getUTCFullYear();
    return month + " " + day + ", " + year;
  }
  return null;
});

// now get the author info
const storyblokApi = useStoryblokApi();
const { data } = await storyblokApi.get("cdn/stories/", {
  by_uuids: props.blogAuthor,
  version: "draft",
});

const author: {
  name: string;
  role: string;
  picture: {
    alt: string;
    filename: string;
  };
} = data.stories[0].content;
</script>

<style scoped>
.picture-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffff;
  padding: 2rem;
  border-radius: 1rem;
  height: 30vh;
  box-shadow: 5px 5px 30px 0px #ffff;
}

.blog-picture {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.blog-container {
  width: 75%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .blog-container {
    width: 100%;
  }
}

.blog-meta {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--seperator-colour);
  width: 100%;
  @apply p-4 mb-12;
}

.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.date {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.author-position {
  margin: 0;
  font-weight: 700;
  font-size: small;
}

.author-img {
  max-height: 4rem;
  border-radius: 100%;
}

.author-img-border {
  display: flex;
  padding: 0.1rem;
  border-radius: 100%;

  background-color: white;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
}
</style>
